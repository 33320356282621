import DBRoutes from "./routers";
import useHttpClient from "./hooks/useHttpClient";
import useFormData from "./hooks/useFormData";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./stores/auth";
import { getCookie, setCookie } from "./hooks/cookieHooks";
import { fetchAPI } from "./hooks/fetchApı";
import Loading from "./components/Loading";
import { setSettingsRed } from "./stores/settings";
import { BiLogoWhatsapp } from "react-icons/bi";
import { FaArrowUp } from "react-icons/fa";

function App() {
    const [loading, setLoading] = useState(true);
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    const dispatch = useDispatch();

    const cookieToken = getCookie('t');

    const token = useSelector(state => state.auth) || cookieToken;

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                if (!token) {
                    const formData = new FormData();

                    const pass = btoa("*-api.1453?User2024*-");

                    formData.append("action", "dostbeykoz");
                    formData.append("page", "login");
                    formData.append("username", "api_user");
                    formData.append("password", pass);
                    const response = await axios.post('https://dostbeykoz.com/api/index.php', formData);
                    const { error, message } = response.data;
                    if (error) {
                        alert(message);
                    } else {
                        const { token } = response.data.data;
                        dispatch(setToken(token));
                        setCookie('t', token, '/');
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
            try {
                const settingData = await fetchAPI('settings', createFormData, sendRequest);
                dispatch(setSettingsRed(settingData[0]));
            } catch (error) {
                console.error("Error fetching settings:", error);
            }
        }

        fetchData();

        // Scroll event listener
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowScrollTopButton(true);
            } else {
                setShowScrollTopButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [createFormData, sendRequest, dispatch, token]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <>
                    <div
                        className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
                        <DBRoutes />
                        <a href="whatsapp://send?phone=+905414646673">
                            <img src="/images/blue.png" className="fixed -bottom-1 -right-20 z-[50] w-[205px]" alt="" />
                            <BiLogoWhatsapp className="fixed bottom-3 right-0 z-[51] text-white cursor-pointer"
                                size={64} />
                        </a>

                        {showScrollTopButton && (
                            <button
                                className="fixed bottom-5 md:right-40 z-[52] p-5 bg-[#2d407a] text-white rounded-full shadow-lg"
                                onClick={scrollToTop}
                            >
                                <FaArrowUp size={24} />
                            </button>
                        )}
                    </div>
                </>
            )}
        </>
    );
}

export default App;
