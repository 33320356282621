import { FC } from "react";
import { printDate } from "utils/printDate";

export interface PostCardMetaProps {
  className?: string;
  meta: any;
  hiddenAvatar?: boolean;
  avatarSize?: string;
}

const PostCardMeta: FC<PostCardMetaProps> = ({
  className = "leading-none text-xs",
  meta,
  hiddenAvatar = false,
  avatarSize = "h-7 w-7 text-sm",
}) => {
  const { date, avatar, displayName } = meta;

  return (
    <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      <div className="relative flex items-center space-x-2">
        {!hiddenAvatar && (
          // <Avatar
          //   radius="rounded-full"
          //   sizeClass={avatarSize}
          //   imgUrl={avatar}
          //   userName={displayName}
          // />
          <img src={avatar} className="rounded-full w-8" alt="" />
        )}
        <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {displayName}
        </span>
      </div>
      <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">

        </span>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal">
          {printDate(date)}
        </span>
      </>
    </div>
  );
};

export default PostCardMeta;
