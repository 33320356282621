import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import { PostAuthorType } from "data/types";
import React, { FC } from "react";
import MySlider from "components/MySlider";

export interface SectionGridAuthorBoxProps {
  className?: string;
  authors: PostAuthorType[];
  heading?: string;
  subHeading?: string;
  itemPerRow?: number,
}

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = "",
  authors,
  itemPerRow = 5,
}) => {
  return (

    <div className={`nc-SectionSliderNewAuthors ${className}`}>
      <MySlider
        itemPerRow={itemPerRow}
        data={authors}
        renderItem={(item, index) => (
          <CardAuthorBox key={index} author={item} />
        )}
      />
    </div>
  );
};

export default SectionGridAuthorBox;
