import React, { FC } from "react";
import { PostDataType } from "data/types";
import MySlider from "components/MySlider";
import Card7 from "components/Card7/Card7";
import Card16Podcast from "components/Card16Podcast/Card16Podcast";
import Card11 from "components/Card11/Card11";

export interface SectionSliderPostsProps {
    className?: string;
    subHeading?: string;
    posts: PostDataType[];
    perView?: 2 | 3 | 4;
    card?: string;
}

const SectionSliderPosts: FC<SectionSliderPostsProps> = ({
    className = "",
    posts,
    perView = 4,
    card
}) => {

    return (
        <div className={`nc-SectionSliderPosts ${className} text-black`}>
            <MySlider
                data={posts}
                renderItem={(item, indx) => {
                    switch (card) {
                        case "Card11":
                            return <Card11 ratio="aspect-w-5 aspect-h-3" post={item} />
                        case "Card16":
                            return <Card16Podcast type="slider" key={indx} post={item} />
                        case "Card7":
                        default:
                            return <Card7 key={indx} post={item} />
                    }
                }}
                itemPerRow={perView}
            />
        </div>
    );
};

export default SectionSliderPosts;
