import React, { useMemo } from 'react';
import { IoLogoBitcoin, IoLogoEuro, IoLogoModelS, IoLogoUsd } from 'react-icons/io';
import { FaCarOn, FaEthereum } from "react-icons/fa6";
import { GiGoldBar } from "react-icons/gi";
import { AiFillGolden } from "react-icons/ai";
import { printDate } from "../../utils/printDate";

const CurrencyComponent = ({ data }) => {
    const { cryptoData, currencyData, gasolineData, goldData } = data;
    const { updatedDate } = currencyData || {};

    const gasolinePrice = useMemo(() => ({
        "benzin": gasolineData?.data?.[1]?.Benzin || "N/A",
        "dizel": gasolineData?.data?.[1]?.Motorin || "N/A"
    }), [gasolineData]);

    const currencyPrice = useMemo(() => ({
        "usd": currencyData?.data?.[0]?.selling?.toFixed(2) || "N/A",
        "eur": currencyData?.data?.[1]?.selling?.toFixed(2) || "N/A"
    }), [currencyData]);

    const goldPrice = useMemo(() => ({
        "ons": goldData?.data?.[1]?.selling?.toFixed(2) || "N/A",
        "gram": goldData?.data?.[0]?.selling?.toFixed(2) || "N/A"
    }), [goldData]);

    const cryptoPrice = useMemo(() => ({
        "bitcoin": cryptoData?.data?.[0]?.price?.toFixed(2) || "N/A",
        "ethereum": cryptoData?.data?.[1]?.price?.toFixed(2) || "N/A"
    }), [cryptoData]);

    const symbolSize = 60;

    const prices = useMemo(() => ([
        { name: 'USD', symbol: <IoLogoUsd size={symbolSize} />, price: '₺' + currencyPrice?.usd },
        { name: 'EUR', symbol: <IoLogoEuro size={symbolSize} />, price: '₺' + currencyPrice?.eur },
        { name: 'Altın(ONS)', symbol: <AiFillGolden size={symbolSize} />, price: '₺' + goldPrice?.ons },
        { name: 'Altın(GRAM)', symbol: <GiGoldBar size={symbolSize} />, price: '₺' + goldPrice?.gram },
        { name: 'Bitcoin', symbol: <IoLogoBitcoin size={symbolSize} />, price: '$' + cryptoPrice?.bitcoin },
        { name: 'Ethereum', symbol: <FaEthereum size={symbolSize} />, price: '$' + cryptoPrice?.ethereum },
        { name: 'Benzin', symbol: <IoLogoModelS size={symbolSize} />, price: '₺' + gasolinePrice?.benzin },
        { name: 'Dizel', symbol: <FaCarOn size={symbolSize} />, price: '₺' + gasolinePrice?.dizel },
    ]), [currencyPrice, goldPrice, cryptoPrice, gasolinePrice]);

    return (
        <div className="bg-gray-900 text-white text-nowrap px-2 md:px-4 lg:px-8 rounded-lg relative">
            <div className="flex items-center overflow-x-auto">
                <div className="flex space-x-2 md:space-x-4 overflow-x-auto mx-auto">
                    {prices.map((item, index) => (
                        <div
                            key={index}
                            className="flex flex-col items-center justify-center my-2 w-24 md:w-36 bg-gray-800 p-2 md:p-4 rounded-lg min-w-24 relative"
                        >
                            <div className="font-bold absolute opacity-15">{item.symbol}</div>
                            <div className="text-xs md:text-sm">{item.name}</div>
                            <div className="text-base md:text-md text-gray-400">{item.price}</div>
                        </div>
                    ))}
                    <span className="absolute bottom-0 right-2 text-xs text-gray-300">
                        Son Güncellenme Tarihi - {printDate(updatedDate)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CurrencyComponent;
