import React from "react";
import Link from "components/Link";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = () => {
  return (
    <Link
      href="/"
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
    >
      <img src="/images/logo.png" alt="" width={200} />
    </Link>
  );
};

export default Logo;
