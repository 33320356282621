import React, { FC } from "react";
import { PostDataType } from "data/types";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import Link from "components/Link";
import Image from "../Image";
import { handleHit } from "../../utils/handleHit";

export interface Card11Props {
    className?: string;
    post: PostDataType;
    ratio?: string;
    hiddenAuthor?: boolean;
}

const Card11: FC<Card11Props> = ({
    className = "h-full",
    post,
    ratio = "aspect-w-4 aspect-h-3",
}) => {
    const { title, href, date, featuredImage, viewdCount, commentCount, kod } = post;

    return (
        <div
            onClick={() => handleHit(kod)}
            className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
        >
            <div
                className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
            >
                <div>
                    <Link
                        href={href}
                        className="block h-0 aspect-w-1 aspect-h-1 relative overflow-hidden shadow-lg "
                    >
                        <Image
                            className="max-w-full h-auto"
                            src={featuredImage}
                            // fill
                            alt={title}
                            sizes="100px"
                        />
                    </Link>
                </div>
            </div>
            <Link href={href} className="absolute inset-0"></Link>
            <span className="absolute top-3 inset-x-3 z-10">
            </span>

            <div className="p-4 pt-0 flex flex-col space-y-3">
                <span className="text-xs text-neutral-500">{date}</span>
                <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
                    <span className="line-clamp-2" title={title}>
                        {title}
                    </span>
                </h3>
                <div className="flex items-end justify-between mt-auto">
                    <PostCardLikeAndComment likeCount={viewdCount} commentCount={commentCount} href={href} className="relative" />
                </div>
            </div>
        </div>
    );
};

export default Card11;
