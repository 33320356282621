
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/index.scss";
import App from "./App";

import { Provider } from "react-redux";
import store from "./stores/index";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
