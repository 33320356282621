import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "pages/home";
import Article from "pages/categories";
import Post from "../pages/article/post";
import Page404 from "../pages/not-found";
import SiteHeader from "../pages/SiteHeader";
import Authors from "../pages/authors";
import AuthorDetails from "../pages/authors/details";
import Infos from "../pages/place/infos";
import Modules from "../pages/place/modules";
import ModuleDetail from "../pages/place/moduleDetail";
import Publications from "../pages/publications";
import PublicationsDetails from "../pages/publications/details";
import VideoGallery from "../pages/video_gallery";
import Footer from "../components/Footer/Footer";
import Cookie from "../components/Cookie";
import Tag from "../pages/tags";

export const pages = [
    { path: "/", component: Home },
    { path: "/#", component: Home },
    { path: "/:slug", component: Post },
    { path: "/yazarlar", component: Authors },
    { path: "/yazarlar/detay/:slug", component: AuthorDetails },
    { path: "/kategori/:slug", component: Article },
    { path: "/bilgiler/:slug", component: Infos },
    { path: "/moduller/:slug", component: Modules },
    { path: "/moduller/:slug/:details", component: ModuleDetail },
    { path: "/yayinlar", component: Publications },
    { path: "/yayinlar/:slug", component: PublicationsDetails },
    { path: "/video-galeri", component: VideoGallery },
    { path: "/etiket/:slug", component: Tag },
    { path: "*", component: Page404 },
];

const DBRoutes = () => {
    return (
        <BrowserRouter>
            <Cookie />
            <SiteHeader />
            <Routes>
                <Route element={<Page404 />} />
                {pages.map(({ component: Component, path }, index) => {
                    return <Route key={index} element={<Component />} path={path} />;
                })}
            </Routes>
            <Footer />
        </BrowserRouter>
    );
};

export default DBRoutes;
