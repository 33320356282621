import { Link } from "react-router-dom";

const BreakingNewsComponent = ({ data }) => {
    return (
        <div className="flex">
            <div className="bg-red-700 text-white border-r-2 rounded-l-lg relative md:w-2/12 w-4/12 text-center">
                <div className="flex items-center justify-center overflow-hidden">
                    <div className="flex space-x-2 md:space-x-4">
                        <strong>SON DAKİKA</strong>
                    </div>
                </div>
            </div>
            <div className="bg-gray-900 text-white rounded-r-lg relative marquee w-11/12">
                <div className="flex items-center overflow-hidden">
                    <div className="flex space-x-2 md:space-x-4 marquee-content">
                        {data.map((item, index) => (
                            <Link to={item.href} key={index} className="px-12 hover:bg-gray-600">{item.title}</Link>
                        ))}
                    </div>
                    <div className="flex space-x-2 md:space-x-4 marquee-content">
                        {data.map((item, index) => (
                            <Link to={item.href} key={`${index}-duplicate`} className="px-12 hover:bg-gray-600">{item.title}</Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreakingNewsComponent;
