import React, { CSSProperties, FC } from "react";
import LazyLoad from "react-lazyload";

interface Props extends Partial<Omit<HTMLImageElement, "style">> {
    fill?: boolean;
    style?: CSSProperties;
    onLoadingComplete?: () => void;
    onClick?: any;
    src?: string;
}

const Image: FC<Props> = ({
    fill = false,
    className,
    alt = "",
    style,
    onLoadingComplete,
    src,
    ...args
}) => {
    return (
        <LazyLoad
            once
            placeholder={<div style={{ height: "100%", width: "100%", backgroundColor: "#f0f0f0" }} />}
        >
            <img
                onClick={args.onClick}
                // {...args}
                className={
                    className + (fill ? " object-cover absolute inset-0 w-full h-full" : "")
                }
                alt={alt}
                width={"100%"}
                style={style}
                onLoad={onLoadingComplete}
                src={src}
            />
        </LazyLoad>
    );
};

export default Image;
