import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Schema({ content, gallery = [], children }) {

    if (!content) {
        return null;
    }

    function degistirMainImage(imageUrl) {
        let yeniUrl = imageUrl.replace(/\.webp$/, '.jpg');
        yeniUrl = yeniUrl.replace(/galeri_/, '');
        return yeniUrl;
    }

    let mainImage = content?.featuredImage || (gallery.length > 0 && gallery[0]?.featuredImage) || 'https://dostbeykoz.com/logo.png';

    mainImage = degistirMainImage(mainImage);

    const schema = {
        "@context": "https://schema.org/",
        "@graph": [
            {
                "@type": "WebPage",
                "@id": window.location.href,
                "url": window.location.href,
                "name": content?.title?.trim() || "Untitled",
                "isPartOf": {
                    "@id": content?.isPartOf || "https://dostbeykoz.com/#website"
                },
                "primaryImageOfPage": {
                    "@id": mainImage || "https://dostbeykoz.com/default-image.jpg"
                },
                "image": {
                    "@id": mainImage || "https://dostbeykoz.com/default-image.jpg"
                },
                "thumbnailUrl": mainImage || "https://dostbeykoz.com/default-image.jpg",
                "datePublished": content?.publishedDate || new Date().toISOString(),
                "dateModified": content?.editDate || new Date().toISOString(),
                "author": {
                    "@id": content?.authorUrl || "https://dostbeykoz.com/#/schema/person/unknown"
                },
                "breadcrumb": {
                    "@id": window.location.href + "#breadcrumb"
                },
                "inLanguage": "tr",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [
                            window.location.href
                        ]
                    }
                ]
            },
            {
                "@type": "ImageObject",
                "inLanguage": "tr",
                "@id": mainImage || "https://dostbeykoz.com/default-image.jpg",
                "url": mainImage || "https://dostbeykoz.com/default-image.jpg",
                "contentUrl": mainImage || "https://dostbeykoz.com/default-image.jpg",
                "width": 800,
                "height": 600,
                "caption": content?.imageCaption || "Default caption"
            },
            {
                "@type": "BreadcrumbList",
                "@id": window.location.href + "#breadcrumb",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Anasayfa",
                        "item": "https://dostbeykoz.com"
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "name": content?.category?.trim() || "Genel",
                        "item": `https://dostbeykoz.com/kategori/${content?.category_seo || 'genel'}`
                    },
                    {
                        "@type": "ListItem",
                        "position": 3,
                        "name": content?.title?.trim() || "Untitled",
                        "item": window.location.href
                    }
                ]
            },
            {
                "@type": "WebSite",
                "@id": "https://dostbeykoz.com/#website",
                "url": "https://dostbeykoz.com/",
                "name": content?.publisher?.trim() || "Dost Beykoz",
                "description": content?.publisherDescription || "Yerel Haber Ajansınız",
                "potentialAction": [
                    {
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": "https://dostbeykoz.com/?s={search_term_string}"
                        },
                        "query-input": {
                            "@type": "PropertyValueSpecification",
                            "valueRequired": true,
                            "valueName": "search_term_string"
                        }
                    }
                ],
                "inLanguage": "tr"
            },
            {
                "@type": "Person",
                "@id": content?.authorUrl || "https://dostbeykoz.com/#/schema/person/unknown",
                "name": content?.author?.trim() || "Haber Merkezi",
                "image": {
                    "@type": "ImageObject",
                    "inLanguage": "tr",
                    "@id": content?.authorImageUrl || "https://dostbeykoz.com/default-author-image.jpg",
                    "url": content?.authorImageUrl || "https://dostbeykoz.com/default-author-image.jpg",
                    "contentUrl": content?.authorImageUrl || "https://dostbeykoz.com/default-author-image.jpg",
                    "caption": content?.author?.trim() || "Haber Merkezi"
                },
                "url": content?.authorUrl || "https://dostbeykoz.com"
            }
        ]
    };

    return (
        <HelmetProvider>
            <Helmet>
                <meta charset="UTF-8" />
                <title>{content?.title}</title>
                <meta name="description" content={content?.spot} />
                <meta name="keywords" content={content?.title} />
                <meta itemprop="publisher" content="DOSTBEYKOZ" />
                <meta name="author" content={content?.author || 'Haber Merkezi'} />
                <link rel='canonical' href={window.location.href} />

                <meta name="robots" content="FOLLOW, INDEX" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

                <meta name="msapplication-starturl" content={window.location.href} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={content?.author || 'Haber Merkezi'} />
                <meta name="twitter:title" content={content?.title} />
                <meta name="twitter:description" content={content?.spot} />

                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={content?.title} />
                <meta property="og:description" content={content?.spot} />
                <meta property="og:image" content={mainImage} />
                <meta property="og:type" content="article" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="600" />
                <meta property="og:site_name" content={content?.publisher || 'Dost Beykoz'} />
                <link rel='image_src' href={mainImage} />
                <meta name="msapplication-window" content="width=1024;height=768" />

                <link rel="alternate" hreflang="tr" href="{window.location.href}" />

                <link rel="alternate" type="application/rss+xml" title={content?.title}
                    href="https://www.dostbeykoz.com/sitemap.xml" />

                <link rel="alternate" type="application/rss+xml" title={content?.title}
                    href="https://www.dostbeykoz.com/news_sitemap.xml" />

                <link rel="shortcut icon" href="/logo-small.png" type="image/x-icon" />

                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <base url="https://www.dostbeykoz.com" />

                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>

            {children}
        </HelmetProvider>
    )
}
